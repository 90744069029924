import moment from "moment-timezone"
import { TimeZones } from "../graphql"

export function formatTimezoneTime(date: Date | string, timezone: TimeZones) {
  const tz = getMomentTimezone(timezone)
  const abbr = getTimezoneAbbreviation(timezone)
  return moment(date).tz(tz).format("MMM D, h:mm A") + " " + abbr
}

export function getMomentTimezone(timezone: TimeZones) {
  switch (timezone) {
    case TimeZones.AlaskaStandard:
      return "America/Anchorage"
    case TimeZones.CentralStandard:
      return "America/Chicago"
    case TimeZones.EasternStandard:
      return "America/New_York"
    case TimeZones.HawaiiAleutianStandard:
      return "America/Honolulu"
    case TimeZones.MountainStandard:
      return "America/Denver"
    case TimeZones.PacificStandard:
      return "America/Los_Angeles"
    case TimeZones.SamoaStandard:
      return "Pacific/Pago_Pago"
    default:
      return moment.tz.guess()
  }
}

export function getTimezoneAbbreviation(timezone: TimeZones) {
  switch (timezone) {
    case TimeZones.AlaskaStandard:
      return "AKST"
    case TimeZones.CentralStandard:
      return "CST"
    case TimeZones.EasternStandard:
      return "EST"
    case TimeZones.HawaiiAleutianStandard:
      return "HST"
    case TimeZones.MountainStandard:
      return "MST"
    case TimeZones.PacificStandard:
      return "PST"
    case TimeZones.SamoaStandard:
      return "SST"
    default:
      return ""
  }
}
